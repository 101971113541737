.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.app {
  display: flex;
  flex-direction: column;
}

.app-header {
  max-height: 48px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  height: 10vh;
}

.drawer-menu {
  flex: 1;
  text-align: left;
}

.user-name {
  flex: 1;
  text-align: right;
  font-size: 15px;
  margin-right: 20px;
}

.app-name {
  flex: 4;
  text-align: center;
}

.app-header .drawer {
  margin-right:20px;
}

#app-inputs,#app-result {
  display: flex;
}

#app-inputs section,
#app-result section {
  flex: 1 1 0;
}

#app-result section div{
  margin: 0 auto !important;
}

#path-app {
  width: 100%;
  line-height: 2.5;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-container {
  display: flex;
  height: 95vh;
}

.app-search-menu {
  width: 20%;
  min-width: 300px;
  background-color: #f0f0f0;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.search-menu-component {
  margin-top: 20px;
}

.app-results {
  width: 80%;
  padding: 20px;
  overflow-y: auto;
  overflow-x: auto;
}

.date-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.date-time-container {
  flex: 1;
  width: calc(50% - 5px);
}

.App-header {
  margin: 20px 20px 20px 17%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}

.App-header p {
  margin-top: 30px;
}

.detailsContainer {
  padding: 50px;
}

.tableHead {
  font-weight: bold !important;
}

.App-link {
  color: #61dafb;
}

.select_app {
  min-width: 16%;
}

.select_payload {
  min-width: 100px;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


